// import logo from './logo.svg';
import './App.css';
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.replace('https://cum.gold')
  }, [])
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <h1>Redirecting...</h1>
      <a style={{ color: "inherit" }} href="https://cum.gold/"><h2>Click if you are not redirected...</h2></a>
    </div>
  );
}

export default App;
